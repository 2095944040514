import Compressor from "compressorjs";
export const isImage = (url) => {
  return /(jpg|png|jpeg|pict)$/i.test(url);
};

export const isJPG = (url) => {
  return /(jpg)$/i.test(url);
};
export const convertToJPG = (file, quality = 1) => {
  return new Promise((resp, rej) => {
    try {
      let _name = file.name;
      _name = `${_name.slice(0, _name.lastIndexOf("."))}.jpg`;

      let read = new FileReader();
      read.readAsDataURL(file);
      const TOTAL_SIZE = file.size;
      read.onload = function () {
        const imgUrl = this.result;
        let img = new Image();
        img.src = imgUrl;
        img.onload = function () {
          let canvas = document.createElement("canvas");
          let toMiBSize = TOTAL_SIZE / (1024 * 1024);
          // 压缩率
          let compRatio = 1;
          if (toMiBSize > 5) {
            compRatio = Math.sqrt(5 / toMiBSize, 2);
          }
          canvas.width = img.naturalWidth * compRatio;
          canvas.height = img.naturalHeight * compRatio;
          let ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          function dataURLToBlob(dataurl) {
            let arr = dataurl.split(",");
            let mime = arr[0].match(/:(.*?);/)[1];
            let bstr = atob(arr[1]);
            let n = bstr.length;
            let u8arr = new Uint8Array(n);
            while (n--) {
              u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], _name, {
              type: mime
            });
          }

          let dataURL = canvas.toDataURL("image/jpeg", quality);
          const timer = TOTAL_SIZE / (1024 * 1024) / 5; // 每 5 M 一秒
          setTimeout(() => {
            resp(dataURLToBlob(dataURL));
          }, timer * 1000);
        };
      };
    } catch (error) {
      rej(error);
    }
  });
};

export const imageToJPG = (file, quality = 1) => {
  let _file = file;
  return new Promise((resp, rej) => {
    try {
      convertToJPG(_file, quality)
        .then((rlt) => {
          resp(rlt);
        })
        .catch((err) => {
          rej(err);
        });
    } catch (error) {
      rej(error);
    }
  });
};

export const compressImage = (file, maxSize, quality = 0.8) => {
  const _file = file;
  return new Promise((resolve, reject) => {
    new Compressor(_file, {
      quality: quality,
      success(result) {
        const newFile = new File([result], _file.name, {
          type: "mimeType"
        });
        if (newFile.size > maxSize) {
          reject("图片文件大小过大，请重试");
        }
        resolve(newFile);
      },
      error(err) {
        reject(err);
      }
    });
  });
};
